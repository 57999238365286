<div class="h-56 w-96 justify-items-center p-4">
    <div class="text-xl font-bold hover:cursor-pointer">
        {{ releaseNote.title }}
    </div>
    <div class="mt-4 flex items-center justify-between gap-8">
        <div class="text-lg text-warning">
            {{ releaseNote.version }}
        </div>
        <div class="text-md text-slate-500">
            <span class="badge hover:badge-outline">
                {{ releaseDate | date }}
            </span>
        </div>
    </div>

    <div [innerHtml]="releaseNote.content" class="mt-4 text-slate-500"></div>
</div>
